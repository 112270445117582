import request from '@/utils/request.js'

console.log('index----', process.env.VUE_APP_MODE)
// 正式环境不加api
const baseUrl = process.env.VUE_APP_MODE === 'production' ? '' : '/api'

// 图形验证码
export function captchaImage () {
  return request({
    url: baseUrl + '/external/login/captchaImage',
    method: 'post',
    responseType: 'arraybuffer' // 这里特殊注明返回格式是文件流
  })
}
// 个人-personal / 企业- enterprise 登录
export function userLogin (params, type) {
  return request({
    url: `${baseUrl}/external/login/${type}`,
    method: 'post',
    params
  })
}
// 金融登录
export function financeLogin (params) {
  return request({
    url: baseUrl + '/finance/login',
    method: 'post',
    data: params
  })
}
// 个人-personal / 企业-enterprise 信息认证接口
export function onAuthentication (params, type) {
  return request({
    url: `${baseUrl}/apiUser/userinfo/${type}/auth`,
    method: 'post',
    params
  })
}
export function uploadCert (params, type) {
  return request({
    url: `${baseUrl}/apiUser/userinfo/${type}/ce`,
    method: 'post',
    params
  })
}
// 发送验证码，已经登录，手机必须在系统已经存在
export function sendSmsCode () {
  return request({
    url: `${baseUrl}/apiUser/userinfo/smsCode`,
    method: 'get'
  })
}
// 核对验证码
export function verifySmsCode (code) {
  return request({
    url: `${baseUrl}/apiUser/userinfo/smsCode/` + code,
    method: 'get'
  })
}
// 文件上传
export function upload (file) {
  const formData = new FormData()
  formData.append('file', file)
  return request.post(`${baseUrl}/common/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
// 获取企业标签列表
export function getEnterpriseLabels () {
  return request({
    url: baseUrl + '/api/enterprisetype/labels',
    method: 'post'
  })
}
// 个人- personal / 企业 - enterprise 用户注册
export function userRegist (params, type) {
  return request({
    url: `${baseUrl}/api/regist/${type}`,
    method: 'post',
    params
  })
}
// 手机验证码,防止恶意调用
export function getSmsCode (params) {
  return request({
    url: baseUrl + '/external/login/smsCode',
    method: 'post',
    params
  })
}
export function getSms (params) {
  return request({
    url: baseUrl + '/external/login/captcha/sms',
    method: 'post',
    params
  })
}
// 用户协议
export function getAgreement (params) {
  return request({
    url: `${baseUrl}/apiUser/userinfo/getAgreement`,
    method: 'post',
    params
  })
}
// 上传头像
export function uploadAvatar (params) {
  return request({
    url: `${baseUrl}/apiUser/userinfo/avatar`,
    method: 'post',
    data: params,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}
// 获取企业个人信息详情
export function getUserInfo () {
  return request({
    url: baseUrl + '/apiUser/userinfo/detail',
    method: 'post'
  })
}
// 修改密码
export function updatePwd (params) {
  return request({
    url: baseUrl + '/apiUser/userinfo/updatePwd',
    method: 'post',
    params
  })
}
// 修改手机号
export function updatePhone (params) {
  return request({
    url: baseUrl + '/apiUser/userinfo/updatePhone',
    method: 'post',
    params
  })
}
// 获取头像信息
export function getAvatar () {
  return request({
    url: baseUrl + '/apiUser/userinfo/getAvatar',
    method: 'post'
  })
}
// 个人-personal/企业-enterprise用户忘记密码
export function forgotPassword (params, type) {
  return request({
    url: `${baseUrl}/api/forgotPassword/${type}`,
    method: 'post',
    params
  })
}
// 登出
export function logout () {
  return request({
    url: baseUrl + '/apiUser/userinfo/logout',
    method: 'post'
  })
}
// 获取首页数据展示
export function getOverviewData () {
  return request({
    url: baseUrl + '/api/mapOverview/data',
    method: 'post'
  })
}
// 新增需求
export function addNeeds (id, params) {
  return request({
    url: `${baseUrl}/apiNeed/needs/add/${id}`,
    method: 'post',
    data: params
  })
}
// 申请金融产品
export function applyFinanceOrder (id, params) {
  return request({
    url: `${baseUrl}/apiOrder/financeorder/apply/${id}`,
    method: 'post',
    params
  })
}
// 用户订单申请列表
export function getOrderList () {
  return request({
    url: baseUrl + '/apiUser/userinfo/order/list',
    method: 'post'
  })
}
// 订单详情
export function getOrderDetail (id) {
  return request({
    url: `${baseUrl}/apiUser/userinfo/order/detail/${id}`,
    method: 'post'
  })
}
// 评价订单
export function assessOrder (params) {
  return request({
    url: `${baseUrl}/apiOrder/financeorder/assess/${params.id}`,
    method: 'post',
    params
  })
}
// 取消订单
export function cancelOrder (id) {
  return request({
    url: `${baseUrl}/apiOrder/financeorder/cancel/${id}`,
    method: 'post'
  })
}
// 获取当前用户系统信息
export function getSysInfoList () {
  return request({
    url: baseUrl + '/apiMessage/info/list',
    method: 'post'
  })
}
// 获取风控信用报告
export function getReportById (params) {
  return request({
    url: baseUrl + '/api/riskControlReport/getReportById',
    method: 'post',
    data: params
  })
}
// 获取企业风控信用报告状态
export function getReportStatus (params) {
  return request({
    url: baseUrl + '/api/riskControlReport/getReportStatus',
    method: 'post',
    data: params
  })
}
// 信用分评估
export function getRiskReport (type) {
  if (type === 2) {
    return request({
      url: baseUrl + '/apiUser/riskReport/enterpriseReport',
      method: 'post'
    })
  }
}
export function getRiskReportAgain (type) {
  if (type === 2) {
    return request({
      url: baseUrl + '/apiUser/riskReport/reportAgain',
      method: 'post'
    })
  }
}
// 首页信用分评估
/*
驻马店市豫资建设发展有限公司
17629301464
*/
export function getRiskReportNoAuth (params) {
  return request({
    url: baseUrl + '/api/riskReport/noAuth',
    method: 'post',
    params
  })
}
// 新增用户反馈
export function addFeedback (params) {
  return request({
    url: baseUrl + '/apiback/feedback/add',
    method: 'post',
    data: params
  })
}
// 订单状态
export function getOrderStateList () {
  return request({
    url: baseUrl + '/api/order/state/list',
    method: 'post'
  })
}
// 获取企业性质列表
export function getEnterprisetypeList () {
  return request({
    url: baseUrl + '/api/enterprisetype/list',
    method: 'post'
  })
}
// 获取行业分类列表
export function getIndustryList () {
  return request({
    url: baseUrl + '/api/industry/list',
    method: 'post'
  })
}
// 获取驻马店区县列表
export function getAreaList () {
  return request({
    url: baseUrl + '/api/area/list',
    method: 'post'
  })
}
// 获取驻马店街道信息列表
export function getStreetList (id) {
  return request({
    // url: baseUrl + '/api/area/street/list',
    url: `${baseUrl}/api/area/street/list/${id}`,
    method: 'post'
  })
}

// 获取金融专区列表
export function getFinanceList () {
  return request({
    url: baseUrl + '/api/topic/list',
    method: 'post'
  })
}
// 获取审核通过的金融产品列表
export function getProductList (params) {
  return request({
    url: baseUrl + '/api/product/list',
    method: 'post',
    params
  })
}
export function getHotProductList () {
  return request({
    url: baseUrl + '/api/product/hot',
    method: 'post'
  })
}
// 废弃 党建金融-Inclusivelist 信易贷-xydlist
export function getOtherProductList (type) {
  return request({
    url: baseUrl + `/api/product/${type}`,
    method: 'post'
  })
}
// 金融产品详情
export function getProductDetail (id) {
  return request({
    url: `${baseUrl}/api/product/detail/${id}`,
    method: 'post'
  })
}
// 获取办理流程列表
export function getProcessList (params) {
  return request({
    url: baseUrl + '/api/process/list',
    method: 'post',
    params
  })
}
// 地图分析
export function mapOverview (areaId) {
  return request({
    url: `${baseUrl}/api/mapOverview/result/${areaId}`,
    method: 'post'
  })
}
export function getMapJson () {
  return request({
    url: `${baseUrl}/api/mapOverview/result`,
    method: 'post'
  })
}
// 获取地图排行
// key - bank / area
export function getMapOverviewByKey (key, type) {
  return request({
    url: `${baseUrl}/api/mapOverview/${key}/${type}`,
    method: 'post'
  })
}
// 获取筛选项列表 - 金融产品类型-producttype 贷款渠道-lendingchannels 贷款额度-loanlimit 贷款期限-loanterm 金融机构-financemechanism
export function getFilterList (payload, params) {
  return request({
    url: `${baseUrl}/api/${payload}/list`,
    method: 'post',
    params
  })
}
// 获取贷款类型 / 担保方式列表
export function getGuaranteeList () {
  return request({
    url: `${baseUrl}/api/guaranteemethod/list`,
    method: 'post'
  })
}
// 需求方式列表
export function getNeedsTypeList () {
  return request({
    url: baseUrl + '/api/needs/type/list',
    method: 'post'
  })
}
// 获取新闻列表
export function getNewsList (params) {
  return request({
    url: baseUrl + '/api/news/list',
    method: 'post',
    params
  })
}
export function getThreeList (params) {
  return request({
    url: baseUrl + '/api/news/threeList',
    method: 'post',
    params
  })
}
// 获取新闻详情
export function getNewsDetail (id) {
  return request({
    url: `${baseUrl}/api/news/detail/${id}`,
    method: 'post'
  })
}
// 获取新闻分类
export function getNewsTpList () {
  return request({
    url: baseUrl + '/api/news/type/list',
    method: 'post'
  })
}
// 获取成功案例列表
export function getCaseList (params) {
  return request({
    url: baseUrl + '/api/case/list',
    method: 'post',
    params
  })
}
// 合作机构
export function getShowList () {
  return request({
    url: baseUrl + '/api/financemechanism/showlist',
    method: 'post'
  })
}
// 机构详情
export function mechanismDetail (id) {
  return request({
    url: baseUrl + '/api/financemechanism/detail/' + id,
    method: 'get'
  })
}
// 评价选项
export function getSatisfactionList () {
  return request({
    url: baseUrl + '/api/order/satisfaction/list',
    method: 'post'
  })
}
// 万人助万企
// /finance/oauth/authorize?clientKey=8W3R945rK4&redirectUrl=https://qyfw.gxj.zhumadian.gov.cn/sso-login
export function authorizeQYFW (params) {
  return request({
    url: baseUrl + '/finance/oauth/authorize',
    method: 'post',
    params
  })
}
export function hasBeenQueried (params) {
  return request({
    url: baseUrl + '/apiUser/riskReport/query',
    method: 'get'
  })
}
export function getMechanismList () {
  return request({
    url: `${baseUrl}/api/financemechanism/list`,
    method: 'post'
  })
}
export function getUserScore (params) {
  return request({
    url: baseUrl + '/apiUser/riskReport/score',
    method: 'get'
  })
}

export function getCarouselList (params) {
  return request(
    {
      url: baseUrl + '/api/banner/1',
      method: 'post',
      params
    }
  )
}
